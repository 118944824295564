/**
 * @ngdoc directive
 * @module gmComponentsModule
 * @name gmTable
 * @description gmTable - used to display general meetings list in table format
 */
'use strict';

angular.module('gmComponentsModule').directive('gmTable', function () {
  return {
    scope: {
      data: '=data',
      status: '=status',
      onPageClick: '&onPageClick',
      onActionClick: '&onActionClick',
      onColumnClick: '&onColumnClick',
      count: '=count',
      pageNumber: '=pageNumber',
      pageSize: '=pageSize',
      sortItem: '=sortItem',
      sortDirection: '=sortDirection',
    },
    transclude: {
      buttons: 'buttons',
    },
    templateUrl:
      'features/modules/general-meeting-module/gm-table-components/gm-table-template.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.actionClick = function (event, generalMeeting) {
          $scope.onActionClick({
            event: event,
            generalMeeting: generalMeeting,
          });
        };
        $scope.goToPage = function (page) {
          $scope.onPageClick({ page: page });
        };

        //Below function is for sorting, this will be called on click of column name
        $scope.sortByColumn = function (column) {
          $scope.sortItem = column;
          if (column === '' || !column) {
            return;
          }
          if (
            $scope.sortType === column &&
            $scope.sortDirection === appConstant.ASCENDING
          ) {
            $scope.sortDirection = appConstant.DESCENDING;
          } else {
            $scope.sortDirection = appConstant.ASCENDING;
          }
          $scope.sortType = column;
          $scope.onColumnClick({
            column: column,
            sortOder: $scope.sortDirection,
          });
        };
      },
    ],
  };
});
