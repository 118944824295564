/**
 * @ngdoc directive
 * @module UIControlModule
 * @name emailTemplateParameters
 * @description emailTemplateParameters - displays parameters for email template
 */
'use strict';
angular
  .module('generalMeetingHomeModule').directive('emailTemplateParameters',
    ['EmailTemplateService', function (EmailTemplateService) {
      return {
        scope: {
          show: '=show',
          templateCode: '=templateCode',
          issuerId: '=issuerId',
        },
        templateUrl: 'features/modules/audit-trail-module/email-template-parameters.template.html',
        controller: ['$scope', function ($scope) {
          $scope.$watch('show', function (show) {
            if (show) {
              EmailTemplateService.getGMEmailTemplateParameters(
                $scope.templateCode).then(
                function (response) {
                  $scope.templateParameters = response.data;
                  $scope.showParametersModal = true;
                }, function () {
                  $scope.templateParameters = [];
                  $scope.showParametersModal = true;
                });
            }
          });

          $scope.$watch('issuerId', function(issuerId) {
            if (issuerId) {
              $scope.issuerLogoUrl = `/v2/public/issuers/${issuerId}/logo`;
            } else {
              $scope.issuerLogoUrl = null;
            }
          });
        }]
      };
    }]);
