/**
 * @ngdoc directive
 * @module gmComponentsModule
 * @name gmActionButton,
 * @description gmActionButton - used to display action buttons based on their status
 * list of status for which the button should be displayed can be provided by attribute visibleFor,
 * list of status for which the button should not be displayed can be provided by attribute not VisibleFor.
 * Both can not be provided at the same time, when bott provided then the visibleFor rule will be used.
 * If not the visibleFor rules are provided then the button will be displayed by default.
 *
 */
'use strict';

angular.module('gmComponentsModule')
.directive('actionButton', function () {
  return {
    scope: {
      type: '@type',
      visibleFor: '@visibleFor',
      notVisibleFor: '@notVisibleFor',
      status: '@status'
    },
    template: '<a ng-if="isVisible" title="{{buttonLabel | translate}}"><span class="{{buttonStyle}}"></span></a>',
    controller: ['$scope',function ($scope) {
      $scope.isVisible = isButtonVisible($scope.notVisibleFor, $scope.visibleFor, $scope.status);
      setButtonAndTitle($scope.type, $scope);
    }]
  };

  function isButtonVisible(notVisibleFor, isVisibleFor, status) {
    if (notVisibleFor) {
      return !(notVisibleFor.indexOf(status) >= 0);
    } else if (isVisibleFor) {
      return isVisibleFor.indexOf(status) >= 0;
    } else {
      return true;
    }
  }

  function setButtonAndTitle(type, $scope) {
    $scope.buttonStyle = '';
    $scope.buttonLabel = '';

    switch(type) {
      case 'edit':
        $scope.buttonStyle = 'glyphicon ocf-icon-large ocf-icon-pencil_edit iconclass';
        $scope.buttonLabel = 'label_edit';
        break;
      case 'delete':
        $scope.buttonStyle = 'glyphicon ocf-icon-large ocf-icon-delete iconclass';
        $scope.buttonLabel = 'label_delete';
        break;
      case 'cancel':
        $scope.buttonStyle = 'glyphicon ocf-icon-large ocf-icon-No iconclass';
        $scope.buttonLabel = 'label_cancel';
        break;
      case 'inviteShareplan':
        $scope.buttonStyle = 'glyphicon ocf-icon-large icon-inviteShareplan iconclass';
        $scope.buttonLabel = 'label_inviteShareplan';
        break;
      case 'notification':
        $scope.buttonStyle = 'glyphicon ocf-icon-large icon-notification iconclass';
        $scope.buttonLabel = 'label_notification';
        break;
      case 'extensionRequest':
        $scope.buttonStyle = 'glyphicon ocf-icon-large icon-createExtnReq iconclass';
        $scope.buttonLabel = 'label_viewEditExtnReq';
        break;
      case 'viewExtnReq':
        $scope.buttonStyle = 'ocf-icon-large fa fa-calendar fa-lg iconclass';
        $scope.buttonLabel = 'label_viewExtnReq';
        break;
      case 'sendEmailEuroclear':
        $scope.buttonStyle = 'glyphicon ocf-icon-large img-send-mail-tick iconclass';
        $scope.buttonLabel = 'label_sendEmailEuro';
        break;
      case 'reSendEmailEuroclear':
        $scope.buttonStyle = 'glyphicon ocf-icon-large img-send-mail-tick-green iconclass';
        $scope.buttonLabel = 'label_reSendEmailEuro';
        break;
      case 'reject':
          $scope.buttonStyle = 'glyphicon ocf-icon-large_no ocf-icon-No iconclass';
          $scope.buttonLabel = 'label_reject';
          break;
      case 'accept':
          $scope.buttonStyle = 'glyphicon ocf-icon-large ocf-icon-tick-yes iconclass';
          $scope.buttonLabel = 'label_accept';
        break;
      case 'vote':
        $scope.buttonStyle = 'glyphicon ocf-icon-large30 icon-vote  iconclass';
        $scope.buttonLabel = 'label_vote';
        break;
      case 'attend':
        $scope.buttonStyle = 'glyphicon ocf-icon-large30 icon-attend  iconclass';
        $scope.buttonLabel = 'label_attend';
        break;
      case 'report':
        $scope.buttonStyle = 'glyphicon ocf-icon-large30 report-icon-button  iconclass';
        $scope.buttonLabel = 'label_download_intermediary_instructions';
        break;
      case 'download':
        $scope.buttonStyle = 'glyphicon ocf-icon-large30 download-arrow-button   iconclass';
        $scope.buttonLabel = 'label_downloadCertificate';
        break;
      case 'attendVirtually':
        $scope.buttonStyle = 'glyphicon ocf-icon-large30 virtualparticiaption-icon-button  iconclass';
        $scope.buttonLabel = 'label_virtual_participation';
        break;
      case 'viewExtensionRequest':
        $scope.buttonStyle = 'glyphicon ocf-icon-large fa fa-calendar fa-lg iconclass';
        $scope.buttonLabel = 'label_viewExtnReq';
        break;
      case 'ShareholderMail':
        $scope.buttonStyle = 'glyphicon ocf-icon-large icon-inviteShareplan iconclass';
        $scope.buttonLabel = 'label_shareHolderEmail';
        break;
      default:
    }
  }
});

