/**
 * @ngdoc directive
 * @module myAccountProfile
 * @name profile
 * @description This directive is used to add password template and different profile templates 
 * 				for My Account tab based on work stations.
 */
var app = angular.module('myAccountProfile', []);

app.directive('profile', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'features/modules/my-accounts-module/profile-template.html'
  };
});
app.directive('password', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'features/modules/my-accounts-module/change-password-template.html'
  };
});
app.directive('details', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'features/modules/my-accounts-module/issuer-group-details.html'
  };
});
app.directive('intermediaryDetails', function() {
	  return {
	    restrict: 'E',
	    replace: true,
	    templateUrl: 'shared/directives/templates/in-group-details.html'
	  };
	});
app.directive('agentDetails', function() {
	  return {
	    restrict: 'E',
	    replace: true,
	    templateUrl: 'shared/directives/templates/agent-group-details.html'
	  };
	});
app.directive('shareHolderProfile', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'features/modules/my-accounts-module/share-holder-profile-template.html'
  };
});
app.directive('sharePlanProfile', function() {
  return {
    restrict: 'E',
    replace: true,
    templateUrl: 'shared/directives/templates/share-plan-profile.html'
  };
});
