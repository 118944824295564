angular.module('UIControlModule')
.directive('gProgressDialog', function () {
  return {
    scope: {
      id: '@id',
      header: '@header',
      dismissButtonLabel: '@dismissButtonLabel',
      serviceUrl: '@serviceUrl',
      maxPollCount: '@maxPollCount',
      isChecking: '=isChecking',
      onClose: '&onClose',
      onError: '&onError',
      onSuccess: '&onSuccess'
    },
    transclude: {
      'loading-message': 'loadingMessage',
      'done-message': 'doneMessage'
    },
    templateUrl: 'shared/directives/templates/g-progress-dialog.html',
    controller: ['$scope', '$interval', '$timeout', '$http', function ($scope, $interval, $timeout, $http) {
      let pollCount = 0;
      let interval;
      $scope.pollingDone = false;

      let statusService = function () {
        return $http({
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          url: $scope.serviceUrl,
          method: 'GET'
        });
      };

      let startPollingService = function () {
        pollCount = 0;
        $scope.pollingDone = false;
        toggleDialog(true);

        interval = $interval(function () {
          statusService().then(function (data) {
            let batchStatus = data.data.batchStatus;
            if (batchStatus !== 'STARTED' && batchStatus !== 'EXECUTING' && batchStatus !== 'UNKNOWN') {
              if (batchStatus === 'COMPLETED') {
                stopPollingService();
                $scope.onSuccess({ data: data });
              } else {
                stopPollingService();
                $scope.onError({ data: data });
              }
            }

            pollCount++;
            if (pollCount > $scope.maxPollCount) {
              stopPollingService();
              $scope.onError({ data: {
                data: { exitCode: 'TIME_OUT' }
              }});
            }
          }, function (data) {
            $scope.onError(data);
            stopPollingService();
          });
        }, 3000);
      }

      let stopPollingService = function () {
        $interval.cancel(interval);
        interval = undefined;
        $scope.pollingDone = true;
        $scope.isChecking = false;
      }

      let toggleDialog = function (isVisible) {
        let modalElement = angular.element('#g-progress-dialog-' + $scope.id);
        if (isVisible === true) {
          // Some small delay is needed because when multiple modal are
          // displayed after each other the modal after the first one will
          // not open
          $timeout(function() {
            modalElement.modal('show');
          }, 500)
        } else {
          modalElement.modal('hide');
        }
      }

      $scope.dismiss = function () {
        toggleDialog(false);
        $scope.onClose();
      }

      $scope.$watch('isChecking', function (isChecking) {
        if (isChecking) {
          startPollingService();
        } else {
          stopPollingService();
        }
      });

      $scope.$on('$destroy', function() {
        stopPollingService();
      });
    }]
  };
})
