/**
 * @ngdoc directive
 * @module gmComponentsModule
 * @name gmActionButton,
 * @description gmActionButton - used to display action buttons based on their status
 * list of status for which the button should be displayed can be provided by attribute visibleFor,
 * list of status for which the button should not be displayed can be provided by attribute not VisibleFor.
 * Both can not be provided at the same time, when bott provided then the visibleFor rule will be used.
 * If not the visibleFor rules are provided then the button will be displayed by default.
 *
 */
'use strict';

angular.module('gmComponentsModule')
.directive('filterButton', function () {
  return {
    scope: {
      status: '@status',
      selected: '=selected',
      showCount: '@showCount',
      count: '@count',
      onSelect: '&onSelect'
    },
    template: '<button ng-click="selectFilter()" ng-class="{\'greenbutton\': selected == status }" class="btn btn-default buttonClass whiteSpaceNormal">{{label | translate}} <span ng-if="showCount == \'true\'">({{count}})</span></button>',
    controller: ['$scope',function ($scope) {
      $scope.label = getButtonLabel($scope.status);

      $scope.selectFilter = function () {
        $scope.selected = $scope.status;
        $scope.onSelect({status: $scope.status});
      }
    }]
  };

  function getButtonLabel(status) {
    switch (status) {
      case appConstant.DRAFT:
        return 'button0';
      case appConstant.PENDING:
        return 'button1';
      case appConstant.TO_BE_PUBLISHED:
        return 'button2';
      case appConstant.LIVE:
        return 'button3';
      case appConstant.COMPLETED:
        return 'button4';
      case appConstant.CANCELLED:
        return 'button5';
      case appConstant.APPROVED:
          return 'extnbtn1';
        case appConstant.REJECTED:
          return 'label_btnRejected';
      default:
    }

    return '';
  }
});
