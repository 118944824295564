/**
 * Code taken from https://github.com/hodgepodgers/ng-intl-tel-input and
 * added $watch to allow prefilling of the flag
 */
angular.module('ngIntlTelInput')
  .directive('ngIntlTelInput', ['ngIntlTelInput', '$log', '$window', '$parse','$timeout',
    function (ngIntlTelInput, $log, $window, $parse,$timeout) {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attr, ctrl) {
          // Warning for bad directive usage.
          if ((!!attr.type && (attr.type !== 'text' && attr.type !== 'tel')) || elm[0].tagName !== 'INPUT') {
            $log.warn('ng-intl-tel-input can only be applied to a *text* or *tel* input');
            return;
          }
          // Override default country.
          if (attr.initialCountry) {
            ngIntlTelInput.set({initialCountry: attr.initialCountry});
          }
          // Initialize.
          ngIntlTelInput.init(elm);
          // Set Selected Country Data.
          function setSelectedCountryData(model) {
              var getter = $parse(model);
              var setter = getter.assign;
              //setter(scope, elm.intlTelInput('getSelectedCountryData'));
  			  $timeout(function(){
  				scope.$apply(function(){
  					setter(scope, elm.intlTelInput('getSelectedCountryData'));
  				});
  			  },100);

            }
          // Handle Country Changes.
          function handleCountryChange() {
            setSelectedCountryData(attr.selectedCountry);
          }
          // Country Change cleanup.
          function cleanUp() {
            angular.element($window).off('countrychange', handleCountryChange);
          }
          // Selected Country Data.
          if (attr.selectedCountry) {
            setSelectedCountryData(attr.selectedCountry);
            angular.element($window).on('countrychange', handleCountryChange);
            scope.$on('$destroy', cleanUp);
          }

          scope.$watch(attr.selectedCountry, function(code) {
            if(code !=undefined ){
              let country = elm.intlTelInput.getCountryData().filter(function(country) {
                        return country.dialCode == code.dialCode;
              })
              if (country.length > 0) {
                 elm.intlTelInput('setCountry', country[0].iso2);
              }
            }
          })

          // Validation.
          ctrl.$validators.ngIntlTelInput = function (value) {
            // if phone number is deleted / empty do not run phone number validation
            if (value || elm[0].value.length > 0) {
                return elm.intlTelInput('isValidNumber');
            } else {
                return true;
            }
          };
          // Set model value to valid, formatted version.
          ctrl.$parsers.push(function (value) {
            return elm.intlTelInput('getNumber');
          });
          // Set input value to model value and trigger evaluation.
          ctrl.$formatters.push(function (value) {
            if (value) {
              if(value.charAt(0) !== '+') {
                value = '+' + value;
              }
              elm.intlTelInput('setNumber', value);
            }
            return value;
          });
        }
      };
    }]);
