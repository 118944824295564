/**
 * @ngdoc directive
 * @module UIControlModule
 * @name tabs, tabpanel, inputText, backButton, backButtonTwice,
 *       stackedBarChart, workstationHeader, generalMeetingTable, feedback,
 *       breadcrumb, updateTitle, richTextEditor,
 * @description tabs and tabpanel directives - used to add tabs and tab panels
 *              to HTML. inputText - used to add a input text field which also
 *              performs the input validation and displays error message.
 *              backButton and backButtonTwice - used for backward navigation of
 *              pages based on user navigation history and window object
 *              history. stackedBarChart - used to add a Bar chart.
 *              workstationHeader - used to add header template. Also header
 *              menus will be loaded based on WS. generalMeetingTable - used to
 *              add the table with pagination, search etc feedback - used to
 *              load the feedback template for the application. breadcrumb -
 *              used to add breadcrumb to all the pages for path trace and
 *              navigation. updateTitle - used to update the page title.
 *              richTextEditor - used to add rich text editor template to the
 *              pages. contentArea - used to add content area. Also the content
 *              will be validated.
 */
angular
  .module('UIControlModule', ['commonModule', 'ngStorage'])
  .directive('tabs', [
    '$filter',
    function ($filter) {
      var directive = {};
      directive.restrict = 'E';
      directive.scope = {};
      directive.transclude = true;
      var tabController = function ($scope) {
        $scope.tabPanes = [];
        this.addTabPane = function (pane) {
          $scope.tabPanes.push(pane);
        };
        $scope.activeClass = function (states, disabled) {
          var className = '';
          if (disabled === true || disabled === 'true') {
            className = 'disabled';
          }
          if (states) {
            var array = states.split(',');
            for (var i = 0; i < array.length; i++) {
              if ($filter('includedByState')(array[i])) {
                className = 'active';
                break;
              }
            }
          }
          return className;
        };
      };
      directive.controller = ['$scope', tabController];
      directive.template =
        '<div class="BootstrapTab">' +
        '<ul role="tablist" class="nav nav-pills accodionTopHide">' +
        '<li ng-repeat ="tab in tabPanes"  ng-class="activeClass(tab.activeStates, tab.disabled)" style="top: 1px" role="presentation">' +
        '<a role="tab" ui-sref="{{tab.url}}" class="colIn untabstyle"> {{tab.label}} </a>' +
        '</li></ul><div ng-transclude ></div></div>';

      return directive;
    },
  ]);

angular.module('UIControlModule').directive('tabpanel', [
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.require = '^^tabs';
    directive.tabPane = {};
    directive.scope = {
      disabled: '<',
    };
    directive.compile = function () {
      return function ($scope, element, atttributes, tabController) {
        var active = false;
        if (
          atttributes.active &&
          (atttributes.active === true || atttributes.active === 'true')
        ) {
          active = true;
        }
        const tabPane = {
          label: atttributes.label,
          url: atttributes.url,
          id: atttributes.id,
          disabled: $scope.disabled,
          active: active,
          activeStates: atttributes.activestates,
        };
        $scope.$watch('disabled', (to, from) => {
          tabPane.disabled = to;
        });
        tabController.addTabPane(tabPane);
      };
    };
    return directive;
  },
]);

angular.module('UIControlModule').directive('myDecimals', function () {
  return {
    require: 'ngModel',
    link: function (scope, elm, attrs, ctrl) {
      elm.bind('change', function () {
        var val = ctrl.$viewValue;
        ctrl.$setViewValue(val * 1);
        ctrl.$render();
      });
    },
  };
});

angular.module('UIControlModule').directive('updateStyle', [
  '$compile',
  function ($compile) {
    return {
      restrict: 'E',
      scope: true,
      link: function (scope, element, attrs) {
        var el;
        attrs.$observe('width', function (width) {
          if (!angular.isDefined(width)) {
            return;
          }
          el = $compile(
            '<style> .photobanner > div { width: ' + width + 'px; } </style>'
          )(scope);
          element.html('');
          element.append(el);
        });
      },
    };
  },
]);
angular.module('UIControlModule').directive('format', [
  '$filter',
  function ($filter) {
    'use strict';
    return {
      require: '?ngModel',
      link: function (scope, elem, attrs, ctrl) {
        if (!ctrl) {
          return;
        }
        ctrl.$formatters.unshift(function () {
          return $filter('number')(ctrl.$modelValue);
        });
        ctrl.$parsers.unshift(function (viewValue) {
          if (viewValue === '') {
            return '';
          } else {
            var plainNumber = viewValue.replace(/[\,\.]/g, ''),
              b = $filter('number')(plainNumber);
            elem.val(b);
            return plainNumber; // TODO this needs to return as number not as String, refactor this but all flow needs to be assessed how the numbers are compared with!
          }
        });
      },
    };
  },
]);
angular.module('UIControlModule').directive('inputText', [
  '$compile',
  function ($compile) {
    var directive = {};
    directive.restrict = 'E';
    directive.require = '^form';
    directive.replace = true;
    directive.template = '<input type="text" ></input> ';
    directive.compile = function () {
      return function (scope, element, attribute, formController) {
        var fullElementName = formController.$name + '.' + attribute.name;
        var temp =
          "<span ng-show='" +
          fullElementName +
          '.$dirty && ' +
          fullElementName +
          ".$valid ' class='  glyphicon glyphicon-ok-sign ' ></span> ";
        temp =
          temp +
          "<span ng-show='" +
          fullElementName +
          '.$dirty && ' +
          fullElementName +
          ".$invalid ' class='  glyphicon glyphicon-alert ' ></span> ";
        var errorAttr = attribute['errors'];
        var validatorList = [];
        if (errorAttr) {
          var array = errorAttr.split('.');
          validatorList = scope;
          for (var k = 0; k < array.length; k++) {
            validatorList = validatorList[array[k]];
          }
        }
        angular.forEach(validatorList, function (validator) {
          temp =
            temp +
            '<span ng-show="' +
            fullElementName +
            '.$dirty && ' +
            fullElementName +
            '.$error.' +
            validator.name +
            '" class="error">' +
            validator.errorMessage +
            '</span> ';
        });
        temp =
          temp +
          '<span ng-repeat="validator in validators" ng-show="' +
          fullElementName +
          '.$dirty && ' +
          fullElementName +
          '.$error[validator.name] " ' +
          'class="error">{{validator.errorMessage}}</span> ';
        var linkFn = $compile(temp);
        var content = linkFn(scope);
        var errorSpans = angular.element(content);
        element.parent().append(errorSpans);
      };
    };
    return directive;
  },
]);

angular.module('UIControlModule').directive('backButton', [
  '$window',
  'UserNavigationHistory',
  '$state',
  function ($window, UserNavigationHistory, $state) {
    return {
      restrict: 'A',
      link: function (scope, elm) {
        elm.on('click', function ($event) {
          $event.stopPropagation();
          var history = UserNavigationHistory.getPreviousState();
          if (history) {
            if (
              history.fromState.name === 'dwtSearch' &&
              history.toState.name === 'dwtSearchDetails'
            ) {
              history.fromStateParams.navigatedFrom = 'goBack';
            }
            $state.go(history.fromState.name, history.fromStateParams, {
              location: true,
            });
          }
        });
      },
    };
  },
]);

angular.module('UIControlModule').directive('backButtonTwice', [
  '$window',
  function ($window) {
    return {
      restrict: 'A',
      link: function (scope, elm) {
        elm.on('click', function ($event) {
          $event.stopPropagation();
          if ($window.history.length) {
            $window.history.back();
            $window.history.back();
          }
        });
      },
    };
  },
]);

angular.module('UIControlModule').directive('workstationHeader', [
  '$translate',
  'menuService',
  '$state',
  '$sessionStorage',
  function ($translate, menuService, $state, $sessionStorage) {
    var directive = {};
    directive.restrict = 'E';
    directive.templateUrl = 'features/modules/header/header-template.html';
    directive.transclude = true;
    directive.scope = {
      language: '=',
      selectedtab: '=',
      menuData: '=menudata',
      workstationData: '=workstationData',
      toState: '=toState',
      fromState: '=fromState',
      logout: '&',
      onclick: '&',
    };
    directive.compile = function () {
      return function (scope) {
        scope.logoutApp = function (url) {
          var fun = scope.logout();
          fun(url);
        };
        scope.clickItem = function (index, item) {
          if (
            item.url === 'generalMeetingHome' ||
            item.url === 'generalMeetings' ||
            item.url === 'gmList' ||
            item.url === 'shGeneralMeetings' ||
            item.url === 'actionHome' ||
            item.url === 'myAccounts'
          ) {
            $sessionStorage.gmTerms = true;
            $sessionStorage.dwtTerms = false;
          } else if (item.url === 'taxReclaimHome') {
            $sessionStorage.gmTerms = false;
            $sessionStorage.dwtTerms = true;
          }
          scope.workstationData.selectedTab = index;
          $state.go(
            item.url,
            {
              selectedIndex: index,
            },
            {
              location: true,
            }
          );
        };
        scope.switchLanguage = function (lang, loggedUser, toState, fromState) {
          scope.workstationData.selectedLang = lang;
          $sessionStorage.selectedLang = lang;
          if (
            undefined !== loggedUser ||
            toState === '/shareholder' ||
            toState === '/registerSH' ||
            toState === '/shGeneralMeetings' ||
            ((toState === '/shareholderlogin' ||
              toState === '/shHomeGeneralMeetings' ||
              toState === '/generalMeetings') &&
              (fromState === '/shareholder' ||
                fromState === '/shGeneralMeetings' ||
                fromState === '/registerSH'))
          ) {
            scope.workstationData.menuData = menuService.getMenuItemsByWorkStationId(
              scope.workstationData.workstationId,
              lang
            );
          }
          $translate.use(lang);
        };
        scope.toggleMenus = function () {
          $('.mobile-only').on('click', function (e) {
            e.stopPropagation();
          });
        };
        scope.collapseMenus = function () {
          $('#bs-example-navbar-collapse-2').collapse('toggle');
        };
      };
    };
    return directive;
  },
]);

angular.module('UIControlModule').directive('generalMeetingTable', [
  '$compile',
  'GeneralMeetingDataTableService',
  'generalMeetingService',
  'PaginationSvc',
  '$sessionStorage',
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.templateUrl =
      'features/modules/general-meeting-module/general-meeting-table-template.html';
    directive.scope = {
      tableData: '=data',
      searchByModelName: '=issuerSearch',
      workStationId: '=workStationId',
      optionClickCallback: '&optionCallback',
      groupId: '=groupId',
    };
    var controller = function (
      $scope,
      PaginationSvc,
      GeneralMeetingDataTableService,
      $filter,
      $sessionStorage
    ) {
      $scope.proxyType = $sessionStorage.proxyType;
      if ($sessionStorage.usertype === 'VC') {
        $scope.selectedIndex = 0;
      } else {
        $scope.selectedIndex = 1;
      }
      $scope.displayCreateExtn = false;
      //Removed code for displaying send mail to euroclear option
      $scope.sortType = 'generalMeetingDate';
      $scope.setPage = function (page) {
        $scope.pager = [];
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }

        // Apply Filter to tableData
        var filterData = $filter('filter')(
          $scope.tableData,
          $scope.searchFilter
        );
        // get pager object from service
        if (filterData) {
          $scope.pager = PaginationSvc.GetPager(filterData.length, page);
          $scope.beginIndex = $scope.pager.startIndex;
        } else {
          $scope.pager = PaginationSvc.GetPager(0);
          $scope.beginIndex = 0;
        }
        $sessionStorage.generalMeetingSelectedPage = $scope.pager.currentPage;
      };
      $scope.$watch(
        'tableData',
        function () {
          var tempData = $filter('filter')(
            $scope.tableData,
            $scope.searchFilter
          );
          if (tempData && tempData.length > 0) {
            var pageNo = $scope.pager ? $scope.pager.currentPage : 1;
            if ($sessionStorage.generalMeetingSelectedPage) {
              pageNo = $sessionStorage.generalMeetingSelectedPage;
            }
            $scope.pager = PaginationSvc.GetPager(tempData.length, pageNo);
            $scope.beginIndex = $scope.pager.startIndex;
          } else {
            $scope.pager = PaginationSvc.GetPager(0);
            $scope.beginIndex = 0;
          }
          $scope.generateCounts();
        },
        true
      );
      $scope.$watch(
        'searchByModelName',
        function (newVal) {
          $scope.searchFilter['issuerName'] = $scope.searchByModelName
            ? $scope.searchByModelName.value
            : '';
          var tempData = $filter('filter')(
            $scope.tableData,
            $scope.searchFilter
          );
          if (tempData) {
            $scope.pager = PaginationSvc.GetPager(tempData.length);
            $scope.beginIndex = $scope.pager.startIndex;
          } else {
            $scope.pager = PaginationSvc.GetPager(0);
            $scope.beginIndex = 0;
          }
          if (undefined !== newVal && newVal.edit) {
            $sessionStorage.generalMeetingSelectedPage =
              $scope.pager.currentPage;
          }
          $sessionStorage.generalMeetingSearchFilter = $scope.searchFilter;
          $scope.generateCounts();
        },
        true
      );
      $scope.onColumnClickDate = function (index) {
        $scope.sortItem = $scope.columnDef[index].sortValue;
        if (
          $scope.columnDef[index].sortValue === '' ||
          !$scope.columnDef[index].sortValue
        ) {
          return;
        }
        if ($scope.sortType === $scope.columnDef[index].sortValue) {
          $scope.sortReverse = !$scope.sortReverse;
        } else {
          $scope.sortReverse = false;
        }
        $scope.sortType = $scope.columnDef[index].sortValue;
      };
      $scope.onFilter = function (button, index) {
        if (index !== $scope.selectedButtonIndex) {
          $scope.selectedButtonIndex = index;
          $scope.searchFilter['status'] = button.code;
          $scope.setPage(1);
          $scope.selectedButton = button;
          var columns = $filter('filter')($scope.columnDef, {
            optionColumn: true,
          });
          angular.forEach(columns, function (col) {
            col.skipColumn = !button.showOptions;
          });
          $sessionStorage.generalMeetingSearchFilter = $scope.searchFilter;
          $sessionStorage.selectedButtonIndex = $scope.selectedButtonIndex;
        }
      };
      $scope.generateCounts = function () {
        angular.forEach($scope.filterButtons, function (button) {
          var searchCriteria = {
            status: button.code,
            issuerName: $scope.searchByModelName
              ? $scope.searchByModelName.value
              : '',
          };
          var filteredData = $filter('filter')(
            $scope.tableData,
            searchCriteria
          );
          button.count = filteredData ? filteredData.length : 0;
        });
      };
      $scope.onOptionClick = function (workStationId, event, generalMeeting) {
        var fun = $scope.optionClickCallback();
        fun(workStationId, event, generalMeeting);
      };
      $scope.displayOption = function (workStationId, generalMeeting) {
        var flag = true;
        if (workStationId === 'VC') {
          flag = false;
          if (generalMeeting.isVoteConfirmed === 'N') {
            flag = true;
          }
          /**
           * added intermediaryDeadlineDateStr in 'if' condition with agent WS(AG) to get icons under action colums
           * ALM-11079 In draft section, Action items are not available for the GM whose only Type & location section is filled
           */
        } else if (
          workStationId === 'IN' ||
          (workStationId === 'AG' &&
            generalMeeting.intermediaryDeadlineDateStr) ||
          workStationId === 'IS'
        ) {
          var todaysDate = '';
          var intDeadLineDate = generalMeeting.intermediaryDeadlineDate;
          if (generalMeeting.intermediaryDeadlineDateStr.indexOf('CET') > -1) {
            todaysDate = generalMeeting.cetMillis;
          } else if (
            generalMeeting.intermediaryDeadlineDateStr.indexOf('GMT') > -1
          ) {
            todaysDate = generalMeeting.gmtMillis;
          }
          //Removed code for displaying send mail to euroclear option
          if (
            (workStationId === 'AG' || workStationId === 'IS') &&
            intDeadLineDate < todaysDate &&
            generalMeeting.pendingER === true
          ) {
            $scope.displayCreateExtn = true;
          } else if (workStationId === 'IN' && intDeadLineDate < todaysDate) {
            $scope.displayCreateExtn = true;
          } else {
            $scope.displayCreateExtn = false;
          }
          if (generalMeeting.status === 'M') $scope.displayCreateExtn = true;
          flag = true;
        }
        return flag;
      };
      $scope.beginIndex = 0;
      $scope.selectedButtonIndex = 0;
      $scope.filterButtons = GeneralMeetingDataTableService.getFilterButtonsByWorkStationId(
        $scope.workStationId,
        $scope.groupId
      );
      var foundLiveGm = false;
      var liveButton = {};
      for (var k = 0; k < $scope.filterButtons.length; k++) {
        if ($scope.filterButtons[k].code === 'L') {
          liveButton = $scope.filterButtons[k];
          $scope.selectedButtonIndex = k;
          foundLiveGm = true;
          break;
        }
      }
      $scope.selectedButton = foundLiveGm
        ? liveButton
        : $scope.filterButtons[0];
      if ($sessionStorage.selectedButtonIndex >= 0) {
        $scope.selectedButtonIndex = $sessionStorage.selectedButtonIndex;
        $scope.selectedButton =
          $scope.filterButtons[$scope.selectedButtonIndex];
      }
      $scope.searchFilter = {};
      $scope.columnDef = GeneralMeetingDataTableService.getColumnDefinationByWorkStationId(
        $scope.workStationId
      );
      var columns = $filter('filter')($scope.columnDef, {
        optionColumn: true,
      });
      angular.forEach(columns, function (col) {
        col.skipColumn = !$scope.selectedButton.showOptions;
      });
      if ($sessionStorage.generalMeetingSearchFilter) {
        $scope.searchFilter = $sessionStorage.generalMeetingSearchFilter;
        if (undefined !== $scope.searchByModelName) {
          $scope.searchByModelName.value = $scope.searchFilter['issuerName'];
          $scope.searchByModelName.edit = false;
        }
      } else {
        var code = foundLiveGm
          ? liveButton.code
          : $scope.filterButtons.length > 0
          ? $scope.filterButtons[0].code
          : '';
        $scope.searchFilter['status'] = code;
        $scope.searchFilter['issuerName'] = $scope.searchByModelName
          ? $scope.searchByModelName.value
          : '';
      }
    };
    directive.controller = [
      '$scope',
      'PaginationSvc',
      'GeneralMeetingDataTableService',
      '$filter',
      '$sessionStorage',
      controller,
    ];
    return directive;
  },
]);

angular.module('UIControlModule').directive('feedback', [
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.transclude = true;
    directive.templateUrl =
      'shared/directives/templates/feedback-template.html';
    var controller = function (
      $scope,
      $sessionStorage,
      $timeout,
      feedbackService
    ) {
      $scope.feedback = {
        comment: '',
        type: 'Q',
      };
      $scope.maximizeFeedBackFlag = true;
      $scope.onSave = function () {
        var feedbackObject = feedbackService.createFeedback(
          $sessionStorage,
          $scope.feedback.type,
          $scope.feedback.comment
        );
        feedbackService.saveFeedback(feedbackObject).then(
          function (response) {
            $scope.successMessage = response.data.message;
            $scope.errorMessage = '';
            $scope.feedback = {
              comment: '',
              type: 'Q',
            };
            $('#closeFeedback').click();
            $('#successMsgFeedback').modal('show');
            $timeout(function () {
              $('#successMsgFeedback').modal('hide');
            }, 2000);
          },
          function (error) {
            $scope.errorMessage = error.data.errors[0].errorMessage;
            $scope.successMessage = '';
          }
        );
      };
      $scope.checkUserType = function () {
        var userType = $sessionStorage ? $sessionStorage.usertype : '';
        return (
          userType === 'SP' ||
          userType === 'SH' ||
          userType === 'IN' ||
          userType === 'IS' ||
          userType === 'VC'
        );
      };
      $scope.minimizeFeedback = function () {
        $scope.maximizeFeedBackFlag = false;
        $scope.$root.$broadcast('onMaximizeFeedback', true);
      };
      $scope.$on('onMinimizeFeedback', function (event, flag) {
        $scope.maximizeFeedBackFlag = flag;
      });
      $scope.clearData = function () {
        $scope.errorMessage = '';
        $scope.successMessage = '';
        $scope.feedback = {
          comment: '',
          type: 'Q',
        };
      };
    };
    directive.controller = [
      '$scope',
      '$sessionStorage',
      '$timeout',
      'feedbackService',
      controller,
    ];
    return directive;
  },
]);

angular.module('UIControlModule').directive('breadcrumb', [
  function () {
    var directive = {};
    directive.restrict = 'E';
    directive.transclude = true;
    directive.templateUrl =
      'shared/directives/templates/breadcrumb-template.html';
    var controller = function (
      $scope,
      $rootScope,
      $sessionStorage,
      UserNavigationHistory,
      $window,
      $state,
      $transitions
    ) {
      /**
       * Just to be clear:
       *
       * - The onSuccess transition hook blocks when the 'to' and 'from' urls are the same.
       *
       * We have a transition hook, here, in the `breadcrumb` component/directive. It:
       *
       * - sets breadcumbDetails
       * and listItems IF `toState.data` (which is usually set in the state declerations as
       * `{ nameList: Array }`), and NOT `fromParams.fromUrl` (which is only set to true in login.js on lines
       * 164 and 243).
       *
       * - We set the selectedTab (in sessionStorage!?) to `toParams.selectedIndex`. Should the breadcrumb
       * component really setting some value that is clearly related to the header? (Hint: the answer is 'no')
       *
       * - Then, if some condition is met (which looks like:
       *  IF fromState AND toState BOTH do NOT have 'abstract',
       *    OR toState == 'shareholder'), we push toState to the manually tracked user navigation history
       * (which we should NOT be keeping in the first place)
       *
       * - Lastly, if toState.url, we scroll to top? Sure, not too complex. But, again, this could be handled
       * in a lot more intuitive way.
       */
      $transitions.onSuccess(
        {
          to: function (state, transition) {
            if (
              transition.to().name ===
                'votingInstructionsIntermediaryLinkedMeeting' ||
              transition.to().name === 'attendanceReqIntermediaryLinkedMeeting'
            ) {
              return true;
            }

            // If it is a anchor # navigation then don't go continue the transition call back,
            // This is to replicate angular-ui-router 0.2.x behaviour
            return transition.to().url !== transition.from().url;
          },
        },
        function (transition) {
          let toState = transition.to();
          let fromState = transition.from();
          let toParams = transition.params('to');
          let fromParams = transition.params('from');
          $scope.listItems = [];
          $scope.breadCrumbDetails = [];

          // fromParams.fromUrl is added to hide the breadcrumb when it not required.
          // set fromParams.fromUrl to true in state params for hiding breadcrumb
          if (toState.data && !fromParams.fromUrl) {
            $scope.listItems = angular.copy(toState.data.nameList);
            $scope.breadCrumbDetails = toParams.breadCrumbDetails;
          }
          if ($sessionStorage.workstationData) {
            $sessionStorage.workstationData.selectedTab =
              toParams.selectedIndex;
          }
          // check the state is root where abstract is true
          if (
            // fromState AND toState both do NOT haveOwnProperty 'abstract'
            (!fromState.hasOwnProperty('abstract') &&
              !toState.hasOwnProperty('abstract')) ||
            toState.name === 'shareholder'
          ) {
            UserNavigationHistory.push(
              toState,
              toParams,
              fromState,
              fromParams,
              $scope.listItems
            );
          }
          // Scroll to top
          if (toState.url) {
            $window.scrollTo(0, 0);
          }
        }
      );
      $scope.onBreadCrumbClick = function (item) {
        var state = UserNavigationHistory.getStateByName(item);
        if (state) {
          $state.go(state.toState.name, state.toStateParam, {
            location: true,
          });
        }
      };
    };
    directive.controller = [
      '$scope',
      '$rootScope',
      '$sessionStorage',
      'UserNavigationHistory',
      '$window',
      '$state',
      '$transitions',
      controller,
    ];
    return directive;
  },
]);

angular.module('UIControlModule').directive('updateTitle', [
  '$rootScope',
  '$filter',
  '$timeout',
  '$transitions',
  function ($rootScope, $filter, $timeout, $transitions) {
    var directive = {};
    directive.restrict = 'A';
    directive.compile = function () {
      return function (scope, element) {
        var listener = function (transition) {
          let toState = transition.to();
          var pageTitle = $filter('translate')('label_page_title');
          if (
            toState.data &&
            toState.data.nameList &&
            toState.data.nameList.length > 0
          ) {
            pageTitle =
              $filter('translate')('label_page_title') +
              ' - ' +
              $filter('translate')(
                toState.data.nameList[toState.data.nameList.length - 1]
              );
          }
          $timeout(
            function () {
              element.text(pageTitle);
            },
            0,
            false
          );
        };
        $transitions.onSuccess({}, listener);
      };
    };
    return directive;
  },
]);

// Below is the directive to capitalize the first letter of the sentence
angular.module('UIControlModule').directive('capitalize', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = !!inputValue
          ? inputValue.charAt(0).toUpperCase() +
            inputValue.substr(1).toLowerCase()
          : '';
        if (transformedInput !== inputValue) {
          modelCtrl.$setViewValue(transformedInput);
          modelCtrl.$render();
        }
        return transformedInput;
      });
    },
  };
});
//Below is the directive to capitalize all letters
angular.module('UIControlModule').directive('uppercaseText', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      scope.newValue1 = '';
      scope.oldValue1 = '';
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = !!inputValue ? inputValue.toUpperCase() : '';
        scope.newValue1 = transformedInput;
        scope.oldValue1 = inputValue;
        return transformedInput;
      });
      //Below function is to bind the current model value
      element.on('focus', function (event) {
        scope.newValue1 = event.currentTarget.value;
        scope.oldValue1 = event.currentTarget.value;
        scope.$apply(function () {
          modelCtrl.$setViewValue(scope.oldValue1);
          modelCtrl.$render();
        });
      });
      //Below function is to bind the updated model value on focus out
      element.on('blur', function (event) {
        scope.$apply(function () {
          if (scope.newValue1 !== scope.oldValue1) {
            modelCtrl.$setViewValue(scope.newValue1);
          } else {
            modelCtrl.$setViewValue(scope.oldValue1);
          }
          modelCtrl.$render();
        });
      });
    },
  };
});
//Below is the directive to convert all letters to lower case
angular.module('UIControlModule').directive('lowercaseText', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      scope.newValue2 = '';
      scope.oldValue2 = '';
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = !!inputValue ? inputValue.toLowerCase() : '';
        scope.newValue2 = transformedInput;
        scope.oldValue2 = inputValue;
        return transformedInput;
      });
      //Below function is to bind the current model value
      element.on('focus', function (event) {
        scope.newValue2 = event.currentTarget.value;
        scope.oldValue2 = event.currentTarget.value;
        scope.$apply(function () {
          modelCtrl.$setViewValue(scope.oldValue2);
          modelCtrl.$render();
        });
      });
      //Below function is to bind the updated model value on focus out
      element.on('blur', function (event) {
        scope.$apply(function () {
          if (scope.newValue2 !== scope.oldValue2) {
            modelCtrl.$setViewValue(scope.newValue2);
          } else {
            modelCtrl.$setViewValue(scope.oldValue2);
          }
          modelCtrl.$render();
        });
      });
    },
  };
});
//Below is the directive to capitalise first letter of evrey word
angular.module('UIControlModule').directive('capitalizeText', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      scope.newValue3 = '';
      scope.oldValue3 = '';
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = '';
        for (var i = 0; i <= inputValue.length; i++) {
          if (
            /[a-zA-Z\_]$/.test(inputValue.charAt(i - 1)) &&
            inputValue.charAt(i)
          ) {
            transformedInput =
              transformedInput + inputValue.charAt(i).toLowerCase();
          } else {
            transformedInput =
              transformedInput + inputValue.charAt(i).toUpperCase();
          }
          scope.newValue3 = transformedInput;
          scope.oldValue3 = inputValue;
        }
        return transformedInput;
      });
      //Below function is to bind the current model value
      element.on('focus', function (event) {
        scope.newValue3 = event.currentTarget.value;
        scope.oldValue3 = event.currentTarget.value;
        scope.$apply(function () {
          modelCtrl.$setViewValue(scope.oldValue3);
          modelCtrl.$render();
        });
      });
      //Below function is to bind the updated model value on focus out
      element.on('blur', function (event) {
        scope.$apply(function () {
          if (scope.newValue3 !== scope.oldValue3) {
            modelCtrl.$setViewValue(scope.newValue3);
          } else {
            modelCtrl.$setViewValue(scope.oldValue3);
          }
          modelCtrl.$render();
        });
      });
    },
  };
});
//Below is the directive to prepopulate 'veb' word expansion for name field
angular.module('UIControlModule').directive('capitalizeTextVeb', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      scope.newValue3 = '';
      scope.oldValue3 = '';
      modelCtrl.$parsers.push(function (inputValue) {
        var transformedInput = '';
        if (
          inputValue.toLowerCase() === 'veb' ||
          inputValue.toLowerCase().indexOf('vereniging van effectenbezitters') >
            -1
        ) {
          transformedInput = "Vereniging van Effectenbezitters ('VEB')";
          scope.newValue3 = transformedInput;
          scope.oldValue3 = inputValue;
        } else {
          for (var i = 0; i <= inputValue.length; i++) {
            if (
              /[a-zA-Z\_]$/.test(inputValue.charAt(i - 1)) &&
              inputValue.charAt(i)
            ) {
              transformedInput =
                transformedInput + inputValue.charAt(i).toLowerCase();
            } else {
              transformedInput =
                transformedInput + inputValue.charAt(i).toUpperCase();
            }
            scope.newValue3 = transformedInput;
            scope.oldValue3 = inputValue;
          }
        }
        return transformedInput;
      });
      //Below function is to bind the current model value
      element.on('focus', function (event) {
        scope.newValue3 = event.currentTarget.value;
        scope.oldValue3 = event.currentTarget.value;
        scope.$apply(function () {
          modelCtrl.$setViewValue(scope.oldValue3);
          modelCtrl.$render();
        });
      });
      //Below function is to bind the updated model value on focus out
      element.on('blur', function (event) {
        scope.$apply(function () {
          if (scope.newValue3 !== scope.oldValue3) {
            modelCtrl.$setViewValue(scope.newValue3);
          } else {
            modelCtrl.$setViewValue(scope.oldValue3);
          }
          modelCtrl.$render();
        });
      });
    },
  };
});
//Below is the directive to convert text entered in initials field to add dots in between the letters and conevert to uppercase
angular.module('UIControlModule').directive('initials', function () {
  return {
    require: 'ngModel',
    link: function (scope, element, attrs, modelCtrl) {
      scope.newValue4 = '';
      scope.oldValue4 = '';
      modelCtrl.$parsers.unshift(function (inputValue) {
        var transformedInput = '';
        var trimmedInput = '';
        trimmedInput = !!inputValue
          ? inputValue.toUpperCase().split(' ').join('')
          : '';
        transformedInput = !!trimmedInput
          ? trimmedInput.toUpperCase().split('.').join('')
          : '';
        transformedInput = !!transformedInput
          ? transformedInput.toUpperCase().split('').join('.') + '.'
          : '';
        scope.newValue4 = transformedInput;
        scope.oldValue4 = inputValue;
        return transformedInput;
      });
      element.on('focus', function (event) {
        scope.newValue4 = event.currentTarget.value;
        scope.oldValue4 = event.currentTarget.value;
        scope.$apply(function () {
          modelCtrl.$setViewValue(scope.oldValue4);
          modelCtrl.$render();
        });
      });
      //Below function is to bind the updated model value on focus out
      element.on('blur', function (event) {
        scope.$apply(function () {
          if (scope.newValue4 !== scope.oldValue4) {
            modelCtrl.$setViewValue(scope.newValue4);
          } else {
            modelCtrl.$setViewValue(scope.oldValue4);
          }
          modelCtrl.$render();
        });
      });
    },
  };
});

angular.module('UIControlModule').directive('iframeContent', [
  '$interval',
  function ($interval) {
    return {
      restrict: 'A',
      scope: {
        iframeContent: '<',
        topNavigation: '<',
      },
      link: function (scope, element, attrs) {
        scope.$watch('iframeContent', function (value) {
          let doc = element[0].contentWindow.document;
          doc.open();
          doc.write(value);
          doc.close();

          element[0].onload = function () {
            // In some occasions the html is written to the iframe, but
            // not visible yet on the screen. When the iframe is not visible
            // the height cannot be calculated.
            // Here we try to determine the height with
            // an interval for x amount of times until the height becomes
            // available.
            let retries = 0;
            let interval = $interval(function () {
              let height = element.contents().find('html').height();
              if (height > 0 || retries > 50) {
                element[0].style.height = height * 1.1 + 'px';
                $interval.cancel(interval);
              }
              retries++;
            }, 100);

            if (scope.topNavigation) {
              element
                .contents()
                .find('a')
                .toArray()
                .forEach((anchor) => {
                  if (!anchor.target) {
                    anchor.target = '_top';
                  }
                });
            }
          };
        });
      },
    };
  },
]);

//Below directive is created to customise the angular transclude directive, to extend the scope of the variable to transcluded html content
angular.module('UIControlModule').directive('scopeTransclude', [
  '$compile',
  function ($compile) {
    return {
      restrict: 'EAC',
      terminal: true,
      compile: function (tElement) {
        // Remove and cache any original content to act as a fallback
        var fallbackLinkFn = $compile(tElement.contents());
        tElement.empty();

        return function ($scope, $element, $attrs, controller, $transclude) {
          if (!$transclude) {
            throw ngTranscludeMinErr(
              'orphan',
              'Illegal use of ngTransclude directive in the template! ' +
                'No parent directive that requires a transclusion found. ' +
                'Element: {0}',
              startingTag($element)
            );
          }

          // If the attribute is of the form: `ng-transclude="ng-transclude"` then treat it like the default
          if ($attrs.scopeTransclude === $attrs.$attr.scopeTransclude) {
            $attrs.scopeTransclude = '';
          }
          var slotName = $attrs.scopeTransclude || $attrs.scopeTranscludeSlot;

          var innerScope = $scope.$new();
          innerScope.customScope = $scope.$parent;
          // If the slot is required and no transclusion content is provided then this call will throw an error
          $transclude(innerScope, ngTranscludeCloneAttachFn, null, slotName);

          // If the slot is optional and no transclusion content is provided then use the fallback content
          if (slotName && !$transclude.isSlotFilled(slotName)) {
            useFallbackContent();
          }

          function ngTranscludeCloneAttachFn(clone, transcludedScope) {
            if (clone.length) {
              $element.append(clone);
            } else {
              useFallbackContent();
              // There is nothing linked against the transcluded scope since no content was available,
              // so it should be safe to clean up the generated scope.
              transcludedScope.$destroy();
            }
          }

          function useFallbackContent() {
            // Since this is the fallback content rather than the transcluded content,
            // we link against the scope of this directive rather than the transcluded scope
            fallbackLinkFn($scope, function (clone) {
              $element.append(clone);
            });
          }
        };
      },
    };
  },
]);
