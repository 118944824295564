angular
.module('dwtModule')
.directive(
        'dwtLandingTable',
        [
         function() {
           var directive = {};
           directive.restrict = 'E';
           directive.templateUrl = 'shared/directives/templates/dwt-table-template.html';
           directive.scope = {
                   optionClickCallback: '&optioncallback',
                   tableData: '=data',
                   searchByModelName: '=issuerSearch',
                   workStationId: '=workStationId',
                   groupId: '=groupId'
           };

           var controller = function($scope, PaginationSvc,
                   GeneralMeetingDataTableService, DwtDataTableService, $filter,
                   $sessionStorage) {
             $scope.displayCreateExtn = false;
             $scope.taxReclaimIndexId = $sessionStorage.taxReclaimIndexId;
             $scope.taxAuthUser = $sessionStorage.isAdmin;
             $scope.setPage = function(page) {
               $scope.pager = [];
               if (page < 1 || page > $scope.pager.totalPages) {
                 return;
               }
               // Apply Filter to tableData
               var filterData = $filter('filter')($scope.tableData,
                       $scope.searchFilter);
               // get pager object from service
               if (filterData) {
                 $scope.pager = PaginationSvc.GetPager(
                         filterData.length, page);
                 $scope.beginIndex = $scope.pager.startIndex;
               } else {
                 $scope.pager = PaginationSvc.GetPager(0);
                 $scope.beginIndex = 0;
               }
               $sessionStorage.generalMeetingSelectedPage = $scope.pager.currentPage;
             };
             $scope.$watch('tableData',
                     function() {
               var tempData = $filter('filter')(
                       $scope.tableData,
                       $scope.searchFilter);
               if (tempData && tempData.length > 0) {
                 var pageNo = $scope.pager
                 ? $scope.pager.currentPage
                         : 1;
                 if ($sessionStorage.generalMeetingSelectedPage) {
                   pageNo = $sessionStorage.generalMeetingSelectedPage;
                 }
                 $scope.pager = PaginationSvc
                 .GetPager(tempData.length,
                         pageNo);
                 $scope.beginIndex = $scope.pager.startIndex;
               } else {
                 $scope.pager = PaginationSvc
                 .GetPager(0);
                 $scope.beginIndex = 0;
               }
               $scope.generateCounts();
             }, true);
             $scope.$watch('searchByModelName',
                     function(newVal) {
               $scope.searchFilter['issuerName'] = $scope.searchByModelName
               ? $scope.searchByModelName.value
                       : '';
               var tempData = $filter('filter')(
                       $scope.tableData,
                       $scope.searchFilter);
               if (tempData) {
                 $scope.pager = PaginationSvc
                 .GetPager(tempData.length);
                 $scope.beginIndex = $scope.pager.startIndex;
               } else {
                 $scope.pager = PaginationSvc
                 .GetPager(0);
                 $scope.beginIndex = 0;
               }
               if (undefined !== newVal
                       && newVal.edit) {
                 $sessionStorage.generalMeetingSelectedPage = $scope.pager.currentPage;
               }
               $sessionStorage.generalMeetingSearchFilter = $scope.searchFilter;
               $scope.generateCounts();
             }, true);
             $scope.onColumnClickDate = function(index) {
               if(index !== 2){
            	   $scope.sortItem = $scope.columnDef[index].sortValue;
                 if ($scope.columnDef[index].sortValue === ''
                   || !$scope.columnDef[index].sortValue) { return; }
                 if ($scope.sortType === $scope.columnDef[index].sortValue) {
                   $scope.sortReverse = !$scope.sortReverse;
                 } else {
                   $scope.sortReverse = false;
                 }
                 $scope.sortType = $scope.columnDef[index].sortValue;
               }
             };
             $scope.onFilter = function(button, index) {
               if (index !== $scope.selectedButtonIndex || $sessionStorage.navFromCreateDwt === true) {
                 $scope.selectedButtonIndex = index;
                 $scope.searchFilter['status'] = button.code;
                 $scope.setPage(1);
                 $scope.selectedButton = button;
                 var columns = $filter('filter')($scope.columnDef, {
                   'optionColumn': true
                 });
                 angular.forEach(columns, function(col) {
                   col.skipColumn = !button.showOptions;
                 });
                 $sessionStorage.generalMeetingSearchFilter = $scope.searchFilter;
                 $sessionStorage.selectedButtonIndex = $scope.selectedButtonIndex;
                 $sessionStorage.navFromCreateDwt = false;
               }
             };
             $scope.generateCounts = function() {
               angular.forEach($scope.filterButtons,
                       function(button) {
                 var searchCriteria = {
                         'status': button.code,
                         'issuerName': ($scope.searchByModelName
                                 ? $scope.searchByModelName.value
                                         : '')
                 };
                 var filteredData = $filter('filter')(
                         $scope.tableData, searchCriteria);
                 button.count = filteredData
                 ? filteredData.length : 0;
               });
             };
             $scope.onOptionClick = function(workStationId, event,
                     generalMeeting) {
               var fun = $scope.optionClickCallback();
               fun(workStationId, event, generalMeeting);
             };
             $scope.displayOption = function(workStationId,
                     generalMeeting) {
               var flag = true;
               if (workStationId === 'VC') {
                 flag = false;
                 if (generalMeeting.isVoteConfirmed === 'N') {
                   flag = true;
                 }
               } else if (workStationId === 'IN') {
                 var todaysDate = new Date();
                 var peEndDate = new Date(generalMeeting.peEndDate);
                 var qrfEndDate = new Date(generalMeeting.qrfEndDate);
                 if(peEndDate.getTime() < todaysDate.getTime() || qrfEndDate.getTime() < todaysDate.getTime()){
                   $scope.displayCreateExtn = true;
                 }
                 flag = true;
               }
               return flag;
             };
             $scope.displaySendEmail =function(generalMeeting){
               var flag = true;
               var dateToday = new Date();
               var qrfEndDate = new Date(generalMeeting.qrfEndDate);
               if(dateToday > qrfEndDate){
                 flag = true; 
               }else{
                 flag = false;
               }
               return flag;
             };
             $scope.beginIndex = 0;
             $scope.selectedButtonIndex = 0;
             $scope.filterButtons = DwtDataTableService
             .getFilterButtonsByWorkStationId(
                     $scope.workStationId, $scope.groupId);
             var foundLiveGm = false;
             var liveButton = {};
             for (var k = 0; k < $scope.filterButtons.length; k++) {
               if ($scope.filterButtons[k].code === 'L') {
                 liveButton = $scope.filterButtons[k];
                 $scope.selectedButtonIndex = k;
                 foundLiveGm = true;
                 break;
               }
             }
             $scope.selectedButton = foundLiveGm ? liveButton
                     : $scope.filterButtons[0];
             if ($sessionStorage.selectedButtonIndex >= 0) {
               $scope.selectedButtonIndex = $sessionStorage.selectedButtonIndex;
               $scope.selectedButton = $scope.filterButtons[$scope.selectedButtonIndex];
             }
             $scope.searchFilter = {};
             $scope.columnDef = DwtDataTableService
             .getColumnDefinationByWorkStationId($scope.workStationId);
             var columns = $filter('filter')($scope.columnDef, {
               'optionColumn': true
             });
             angular.forEach(columns, function(col) {
               col.skipColumn = !$scope.selectedButton.showOptions;
             });
             if ($sessionStorage.generalMeetingSearchFilter) {
               $scope.searchFilter = $sessionStorage.generalMeetingSearchFilter;
               if (undefined !== $scope.searchByModelName) {
                 $scope.searchByModelName.value = $scope.searchFilter['issuerName'];
                 $scope.searchByModelName.edit = false;
               }
             } else {
               var code = foundLiveGm ? liveButton.code
                       : ($scope.filterButtons.length > 0
                               ? $scope.filterButtons[0].code : '');
               $scope.searchFilter['status'] = code;
               $scope.searchFilter['issuerName'] = $scope.searchByModelName
               ? $scope.searchByModelName.value : '';
             }
             if($scope.workStationId === 'AG' && $scope.taxAuthUser === 'R'){
               $scope.onFilter($scope.filterButtons[4], 4);
             }
             if($sessionStorage.navFromCreateDwt === true){
               $scope.onFilter($scope.filterButtons[$sessionStorage.selectedButtonIndex], $sessionStorage.selectedButtonIndex);
             }
             $scope.helpText = '<div>'+$filter('translate')('label_helpText1')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText2')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText3')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText4')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText5')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText6')+'</div>'+
             '<div>'+'&#9658;&nbsp;'+$filter('translate')('label_helpText7')+'</div>';
           };
           directive.controller = ['$scope', 'PaginationSvc',
                                   'GeneralMeetingDataTableService','DwtDataTableService', '$filter',
                                   '$sessionStorage', controller];
           return directive;
         }]);
