/**
 * @ngdoc directive
 * @module vpComponentsModule
 * @name vpTable
 * @description vpTable - used to display virtual meetings list in table format
 */
'use strict';

angular
  .module('vpComponentsModule')
  .directive(
    'vpTable',
    function() {
      return {
        scope : {
          data : '=data',
          status : '=status',
          count : '=count',
          onActionClick : '&onActionClick',
          pageNumber : '=pageNumber',
          pageSize : '=pageSize',
          onPageClick: '&onPageClick',
        },
        transclude : {
          'buttons' : 'buttons'
        },
        templateUrl : 'features/modules/virtual-participation/virtual-particication-components/vp-table-template.html',
        controller : ['$scope', function($scope) {
          $scope.actionClick = function(event,
            registrationDetails) {
            $scope.onActionClick({
              event : event,
              registrationDetails : registrationDetails
            });
          };

                            
          /*pagination function calling*/
          $scope.goToPage = function (page) {
            $scope.onPageClick({page: page});
          };

          /*to show header according to the status*/
          var getByStatus = function(status) {
            $scope.status = status;
            if ($scope.status == appConstant.PENDING) {

              return [
                {
                  'headerName' : 'label_shareholder',
                  'className' : 'col-md-2',
                  'dataIndex' : 'shareholderFullName'
                },
                {
                  'headerName' : 'label_noOfSecurities',
                  'className' : 'col-md-2',
                  'dataIndex' : 'numberOfSecurities'
                },
                {
                  'headerName' : 'label_shareClasses',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityClass'
                },
                {
                  'headerName' : 'label_securityAccountNumber',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityAcctNo'
                }, {
                  'headerName' : 'label_pn',
                  'className' : 'col-md-2',
                  'dataIndex' : 'phoneNo'
                }, {
                  'headerName' : 'label_options',
                  'className' : 'col-md-2'
                } ];
            } else if ($scope.status == appConstant.CANCELLED) {
              return [
                {
                  'headerName' : 'label_shareholder',
                  'className' : 'col-md-2',
                  'dataIndex' : 'shareholderFullName'
                },
                {
                  'headerName' : 'label_noOfSecurities',
                  'className' : 'col-md-2',
                  'dataIndex' : 'numberOfSecurities'
                },
                {
                  'headerName' : 'label_shareClasses',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityClass'
                },
                {
                  'headerName' : 'label_registrationNumber',
                  'className' : 'col-md-2',
                  'dataIndex' : 'registrationNo'
                },
                {
                  'headerName' : 'label_securityAccountNumber',
                  'className' : 'col-md-3',
                  'dataIndex' : 'securityAcctNo'
                }, {
                  'headerName' : 'label_pn',
                  'className' : 'col-md-1',
                  'dataIndex' : 'phoneNo'
                } ];
            } else if ($scope.status == appConstant.APPROVED) {
              return [
                {
                  'headerName' : 'label_shareholder',
                  'className' : 'col-md-2',
                  'dataIndex' : 'shareholderFullName'
                },
                {
                  'headerName' : 'label_noOfSecurities',
                  'className' : 'col-md-2',
                  'dataIndex' : 'numberOfSecurities'
                },
                {
                  'headerName' : 'label_shareClasses',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityClass'
                },
                {
                  'headerName' : 'label_registrationNumber',
                  'className' : 'col-md-1',
                  'dataIndex' : 'registrationNo'
                },
                {
                  'headerName' : 'label_securityAccountNumber',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityAcctNo'
                }, {
                  'headerName' : 'label_pn',
                  'className' : 'col-md-2',
                  'dataIndex' : 'phoneNo'
                }, {
                  'headerName' : 'label_options',
                  'className' : 'col-md-1'
                } ];
            } else {

              return [
                {
                  'headerName' : 'label_shareholder',
                  'className' : 'col-md-2',
                  'dataIndex' : 'shareholderFullName'
                },
                {
                  'headerName' : 'label_noOfSecurities',
                  'className' : 'col-md-2',
                  'dataIndex' : 'numberOfSecurities'
                },
                {
                  'headerName' : 'label_shareClasses',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityClass'
                },
                {
                  'headerName' : 'label_registrationNumber',
                  'className' : 'col-md-1',
                  'dataIndex' : 'registrationNo'
                },
                {
                  'headerName' : 'label_securityAccountNumber',
                  'className' : 'col-md-2',
                  'dataIndex' : 'securityAcctNo'
                },
                {
                  'headerName' : 'label_pn',
                  'className' : 'col-md-2',
                  'dataIndex' : 'phoneNo'
                },
                {
                  'headerName' : 'lable_Reject_reason',
                  'className' : 'col-md-1',
                  'dataIndex' : 'reason'
                } ];
            }
          };


          /* update table header when status change*/
          $scope.$watch('status', function(status) {
            $scope.tableHeaders = getByStatus($scope.status);
          });
          $scope.tableHeaders = getByStatus($scope.status);
        }]
      };
    });
