/**
 * @ngdoc directive
 * @module uiComponentsModule
 * @name paginationButtons,
 * @description gmActionButton - used to display action buttons based on their status
 * list of status for which the button should be displayed can be provided by attribute visibleFor,
 * list of status for which the button should not be displayed can be provided by attribute not VisibleFor.
 * Both can not be provided at the same time, when bott provided then the visibleFor rule will be used.
 * If not the visibleFor rules are provided then the button will be displayed by default.
 *
 */
'use strict';

angular
.module('uiComponentsModule', ['commonModule'])
.directive('paginationButtons', ['PaginationSvc', function (PaginationSvc) {
  /**
   * This directive is a common directive to handle pagination which should replace pagination directive
   */
  return {
    restrict: 'E',
    scope: {
      page: '=page',
      pageSize: '=pageSize',
      totalItems: '=totalItems',
      onPageClick: '&onPageClick'
    },
    templateUrl: 'shared/directives/templates/pagination-buttons-template.html',
    controller:['$scope', function ($scope) {
      $scope.pager = PaginationSvc.GetPager(0, $scope.page, $scope.pageSize);

      $scope.$watch('page', function(page) {
        $scope.pager = PaginationSvc.GetPager($scope.totalItems, page, $scope.pageSize);
      });

      $scope.$watch('totalItems', function(totalItems){
        $scope.pager = PaginationSvc.GetPager(totalItems, $scope.page, $scope.pageSize);
      });

      $scope.setPage = function (page) {
        $scope.pager = PaginationSvc.GetPager($scope.totalItems, page, $scope.pageSize);
        $scope.page = $scope.pager.currentPage;

        $scope.onPageClick({page: page});
      };
    }]
  };
}]);
