/**
 * @ngdoc directive
 * @module ReorderModule
 * @name reorderable
 * @description This directive is used to reorder the data.
 */
angular.module('ReorderModule', []).directive('reorderable',
        ['$compile', function() {
          var directive = {};
          directive.restrict = 'A';
          directive.scope = {
            dataIndex: "=dataindex",
            onReorder: "&onreorder",
            allowReoder: "=reorderallow"
          };

          directive.compile = function() {

            return function($scope, element) {
              element[0].draggable = true;

              element[0].ondragstart = function(event) {

                if (!$scope.allowReoder) {
                  if (event.preventDefault) {
                  //below line is necessary. Stops redirect.
                    event.preventDefault();
                    event.stopPropagation();
                  }
                  return false;
                }
                event.dataTransfer.setData("text", '' + $scope.dataIndex);
              };

              element[0].ondrop = function(event) {

                if (event.preventDefault) {
                //below line is necessary. Stops redirect.
                  event.preventDefault();
                  event.stopPropagation();
                }
                var srcDataIndex = event.dataTransfer.getData("text");
                var func = $scope.onReorder();
                func(parseInt(srcDataIndex,10), $scope.dataIndex);
                $scope.$apply();
                return false;
              };

              element[0].ondragenter = function(event) {
                if (event.preventDefault) {
                //below line is necessary. Stops redirect.
                  event.preventDefault();
                  event.stopPropagation();
                }
                return false;
              };

              element[0].ondragover = function(event) {
                if (event.preventDefault) {
               //below line is necessary. Stops redirect.
                  event.preventDefault();
                  event.stopPropagation();
                }
                return false;
              };

            };

            //return linkFunction;
          };


          return directive;
        }]);
