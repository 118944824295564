import { ACCOUNT_LABELS, GM_LABELS, MenuItem } from './_common';

export function getVoteCollectorMenuItems(lang = 'EN') {
  const labels = {
    gm: GM_LABELS,
    account: ACCOUNT_LABELS,
  };
  return [
    new MenuItem(labels.gm[lang], 'generalMeetingHome', 0),
    new MenuItem(labels.account[lang], 'myAccounts', 2),
  ];
}

export function sortVcSelection(vcSelection) {
  let agentVcSelection = vcSelection.filter((vc) => vc.isAgent);
  let issuerVcSelection = vcSelection.filter((vc) => vc.isIssuer);
  let proxyVcSelection = vcSelection.filter(
    (vc) => !vc.isAgent && !vc.isIssuer
  );
  return [
    ...agentVcSelection.sort(compareEmail),
    ...issuerVcSelection.sort(compareEmail),
    ...proxyVcSelection.sort(compareGroupName),
  ];
}

function compareGroupName(a, b) {
  if (a.vcName.toLowerCase() < b.vcName.toLowerCase()) {
    return -1;
  }
  if (a.vcName.toLowerCase() > b.vcName.toLowerCase()) {
    return 1;
  }
  // within the same group compare email
  if (a.vcEmailID.toLowerCase() < b.vcEmailID.toLowerCase()) {
    return -1;
  }

  return 1;
}

function compareEmail(a, b) {
  if (a.vcEmailID.toLowerCase() < b.vcEmailID.toLowerCase()) {
    return -1;
  }
  return 1;
}
