import './templates/modal-simple.scss';

/**
 * @ngdoc directive
 * @module gmModal
 * @name modal, modal2, alertBox, alertBoxWithReason
 * @description These directives are used to add modal windows. modal is used to
 *              display a simple modal window with header, body, footer and 2
 *              buttons modal2 is used to display a modal window with header,
 *              body, footer and 3 buttons alertBox is used to alert a message
 *              using a modal window with header, body, footer and a OK button
 *              alertBoxWithReason is used to display a modal window with
 *              header, body, footer and 2 buttons, along with a text area to
 *              enter text/reason which is a mandatory field for rejection or
 *              any action configured
 */
var myModal = angular.module('gmModal', []);

myModal.directive('modal', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalNewEndDate: '=modalNewEndDate',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop';
      },
    ],
  };
});
myModal.directive('modaldwt', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalNewEndDate: '=modalNewEndDate',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/dwt-modal-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop';
      },
    ],
  };
});
myModal.directive('modal17', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalNewEndDate: '=modalNewEndDate',
      modalValidationErrorMsg: '=modalValidationErrorMsg',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-er-model-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop';
      },
    ],
  };
});
myModal.directive('modal18', function () {
  return {
    restrict: 'EA',
    scope: {
      modalHeader: '=modalHeader',
      modalFooter: '=modalFooter',
      modalGroupName: '=modalGroupName',
      modalDisplayName: '=modalDisplayName',
      modalValidationErrorMsg: '=modalValidationErrorMsg',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalColonStar: '=modalColonStar',
      modalColon: '=modalColon',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl:
      'shared/directives/templates/add-vc-display-name-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop18';
      },
    ],
  };
});
myModal.directive('modal99', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo99',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop99';
      },
    ],
  };
});
myModal.directive('modalAgendaDelete', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'popAgendaDelete';
      },
    ],
  };
});

myModal.directive('modal5', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo5',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop5';
      },
    ],
  };
});

myModal.directive('modal7', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template-alert.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop7';
      },
    ],
  };
});

myModal.directive('modal8', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/gm-model-template-alert.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop8';
      },
    ],
  };
});

myModal.directive('singleButtonAlert', function () {
  return {
    restrict: 'E',
    scope: {
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      id: '@',
      callbackbuttonright: '&ngClickRightButton',
    },
    templateUrl:
      'shared/directives/templates/modal-callback-single-button.html',
    transclude: true,
  };
});

myModal.directive('modal2', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonleft2: '&ngClickLeftButton2',
      callbackbuttonright: '&ngClickRightButton',
      handler: '=lolo2',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/model-double-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop2';
      },
    ],
  };
});

myModal.directive('modal3', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalBody1: '=modalBody1',
      modalBody2: '=modalBody2',
      modalBody3: '=modalBody3',
      modalBody4: '=modalBody4',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonleft2: '&ngClickLeftButton2',
      callbackbuttonright: '&ngClickRightButton',
      disableModalBtn: '=ngDisabled',
      handler: '=lolo3',
      modalIndex: '=',
    },
    templateUrl:
      'shared/directives/templates/model-voting-instruction-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop3';
      },
    ],
  };
});
myModal.directive('modal9', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      id: '@',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalBody1: '=modalBody1',
      modalBody2: '=modalBody2',
      modalBody3: '=modalBody3',
      modalBody4: '=modalBody4',
      modalBody5: '=modalBody5',
      modalBody6: '=modalBody6',
      modalBody7: '=modalBody7',
      modalBody8: '=modalBody8',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonleft2: '&ngClickLeftButton2',
      callbackbuttonright: '&ngClickRightButton',
      downloadtermsofuse: '=downloadtermsofuse',
      downloadtou: '&ngDownloadTou',
      disableModalBtn: '=ngDisabled',
      handler: '=lolo9',
      modalIndex: '=',
    },
    templateUrl:
      'shared/directives/templates/model-intermediary-BO-accept-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop9';
        $scope.resetAllCheckBoxes = function () {
          $scope.chk1 = false;
          $scope.chk2 = false;
          $scope.chk3 = false;
          $scope.chk4 = false;
          $scope.chk5 = false;
          $scope.chk6 = false;
          $('.modal').modal('hide');
        };
      },
    ],
  };
});
myModal.directive('modal6', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalBody1: '=modalBody1',
      modalBody2: '=modalBody2',
      modalBody3: '=modalBody3',
      modalBody4: '=modalBody4',
      modalBody5: '=modalBody5',
      modalBody6: '=modalBody6',
      modalBody7: '=modalBody7',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      disableModalBtn: '=ngDisabled',
      handler: '=lolo6',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/model-intermediary-details.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop6';
      },
    ],
  };
});

myModal.directive('alertBox', function () {
  return {
    restrict: 'E',
    scope: {
      config: '=',
      id: '@',
      allowHtml: '=',
    },
    templateUrl: 'shared/directives/templates/modal-simple-template.html',
    transclude: true,
  };
});

myModal.directive('alertBoxWithReason', function () {
  return {
    restrict: 'E',
    scope: {
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
    },
    templateUrl: 'shared/directives/templates/model-with-reason-template.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getReason = function () {
          $sessionStorage.rejectReason = $scope.reason;
          $('.modal').modal('hide');
        };
        $scope.clearData = function () {
          $scope.reason = '';
        };
      },
    ],
  };
});
myModal.directive('reasonAndDoubleButton', function () {
  return {
    restrict: 'E',
    scope: {
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalBodyTitle: '=modalBodyTitle',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonleft2: '&ngClickLeftButton2',
      callbackbuttonright: '&ngClickRightButton',
    },
    templateUrl:
      'shared/directives/templates/model-reason-double-button-template.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getReason = function () {
          $sessionStorage.extenRejectReason = $scope.reason;
          $('.modal').modal('hide');
        };
        $scope.clearData = function () {
          $scope.reason = '';
        };
      },
    ],
  };
});
myModal.directive('doubleButtonAlert', function () {
  return {
    restrict: 'E',
    scope: {
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
    },
    templateUrl:
      'shared/directives/templates/modal-callback-double-button.html',
    transclude: true,
  };
});
myModal.directive('alertBoxWithCommentsPdf', function () {
  return {
    restrict: 'E',
    scope: {
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalFooter: '=modalFooter',
      modalBodyTitle: '=modalBodyTitle',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
    },
    templateUrl:
      'shared/directives/templates/modal-with-comments-template.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getComments = function () {
          $sessionStorage.comments = $scope.comments;
          $('.modal').modal('hide');
        };
        $scope.clearData = function () {
          $scope.comments = '';
          $scope.hidepdf = false;
        };
      },
    ],
  };
});
myModal.directive('selectLanguageDownload', function () {
  return {
    restrict: 'E',
    scope: {
      modalBody: '=modalBody',
      modalHeader: '=modalHeader',
      modalFooter: '=modalFooter',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      downloadhref: '=downloadhref',
    },
    templateUrl: 'shared/directives/templates/modal-language-select.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getLanguageSelected = function () {
          $sessionStorage.languageSelected = $scope.languageSelected;
          $('.modal').modal('hide');
        };
        $scope.clearLanguageSelected = function () {
          $scope.languageSelected = 'EN';
        };
      },
    ],
  };
});

myModal.directive('selectLanguageSendreg', function () {
  return {
    restrict: 'E',
    scope: {
      modalBody: '=modalBody',
      modalHeader: '=modalHeader',
      modalFooter: '=modalFooter',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      downloadhref: '=downloadhref',
    },
    templateUrl:
      'shared/directives/templates/modal-lang-sendregtkt-select.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getLanguageSelected = function () {
          $sessionStorage.languageSelected = $scope.languageSelected;
          $('.modal').modal('hide');
        };
        $scope.clearLanguageSelected = function () {
          $scope.languageSelected = 'EN';
        };
      },
    ],
  };
});
myModal.directive('modalreasontou', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalBody1: '=modalBody1',
      modalBody2: '=modalBody2',
      modalBody3: '=modalBody3',
      modalBody4: '=modalBody4',
      modalBody5: '=modalBody5',
      modalBody6: '=modalBody6',
      modalBody7: '=modalBody7',
      modalFooter: '=modalFooter',
      id: '@',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonright: '&ngClickRightButton',
      downloadtermsofuse: '=downloadtermsofuse',
      downloadtou: '&ngDownloadTou',
      disableModalBtn: '=ngDisabled',
      handler: '=lolo11',
      modalIndex: '=',
    },
    templateUrl: 'shared/directives/templates/model-reason-tou-template.html',
    transclude: true,
    controller: [
      '$scope',
      '$sessionStorage',
      function ($scope, $sessionStorage) {
        $scope.getReason = function () {
          $sessionStorage.rejectReason = $scope.reason;
          $('.modal').modal('hide');
        };
        $scope.clearData = function () {
          $scope.reason = '';
        };
        $scope.handler = 'pop10';
        $scope.resetAllCheckBoxes = function () {
          $scope.chk1 = false;
          $scope.chk2 = false;
          $scope.chk3 = false;
          $scope.chk4 = false;
          $scope.chk5 = false;
          $('.modal').modal('hide');
        };
      },
    ],
  };
});
myModal.directive('modal12', function () {
  return {
    restrict: 'EA',
    scope: {
      title: '=modalTitle',
      modalHeader: '=modalHeader',
      modalBody: '=modalBody',
      modalBody1: '=modalBody1',
      modalBody2: '=modalBody2',
      modalBody3: '=modalBody3',
      modalBody4: '=modalBody4',
      modalBody5: '=modalBody5',
      modalBody6: '=modalBody6',
      modalBody7: '=modalBody7',
      modalBody8: '=modalBody8',
      modalFooter: '=modalFooter',
      modalRightButton: '=modalRightButton',
      modalLeftButton: '=modalLeftButton',
      modalLeftButton2: '=modalLeftButton2',
      callbackbuttonleft: '&ngClickLeftButton',
      callbackbuttonleft2: '&ngClickLeftButton2',
      callbackbuttonright: '&ngClickRightButton',
      disableModalBtn: '=ngDisabled',
      linkedMeeting: '=linkedMeeting',
      handler: '=lolo12',
      modalIndex: '=',
    },
    templateUrl:
      'shared/directives/templates/model-sh-voting-instruction-template.html',
    transclude: true,
    controller: [
      '$scope',
      function ($scope) {
        $scope.handler = 'pop12';
      },
    ],
  };
});

myModal.service('ModelWindowService', function () {
  this.showModelWindow = function (id) {
    if (id) {
      var modelId = '#' + id + '_modal';
      $(modelId).modal('show');
    }
  };
});
