angular.module('UIControlModule')
.directive('gModal', function () {
  return {
    scope: {
      id: '@id',
      visible: '=visible',
      hasCloseButton: '=?hasCloseButton',
      header: '@header',
      footer: '@footer',
      dismissButtonLabel: '@dismissButtonLabel',
      confirmButtonLabel: '@confirmButtonLabel',
      confirmDisabled: '=confirmDisabled',
      onDismiss: '&onDismiss',
      onConfirm: '&onConfirm',
      onClose: '&onClose'
    },
    transclude: true,
    templateUrl: 'shared/directives/templates/g-modal-template.html',
    controller: ['$scope', '$timeout', function ($scope, $timeout) {
      if ($scope.hasCloseButton === undefined) {
        $scope.hasCloseButton = true;
      }
      if ($scope.visible === undefined) {
        $scope.visible = false;
      }

      $scope.$watch('dismissButtonLabel', function(label) {
        $scope.showDismissButton = label !== undefined && label !== '';
      })

      $scope.$watch('visible', function(value) {
        let modalElement = angular.element('#g-modal-' + $scope.id);
        if (value === true) {
          // Some small delay is needed because when multiple modal are
          // displayed after each other the modal after the first one will
          // not open
          $timeout(function() {
            modalElement.modal('show');
          }, 300)
        } else {
          modalElement.modal('hide');
        }
      })

      $scope.dismiss = function() {
        $scope.visible = false;
        $scope.onDismiss();
      }

      $scope.confirm = function() {
        $scope.visible = false;
        $scope.onConfirm();
      }

      $scope.close = function() {
        $scope.visible = false;
        $scope.onClose();
      }
    }]
  };
})
.directive('gConfirmDialog', function () {
  return {
    scope: {
      id: '@id',
      message: '@message',
      ctrl: '=ctrl'
    },
    templateUrl: 'shared/directives/templates/g-confirm-dialog.html',
    controller: ['$scope', '$timeout', '$q', function ($scope, $timeout, $q) {
      if (!$scope.ctrl) {
        $scope.ctrl = {};
      }

      angular.extend($scope.ctrl, {
        open: function () {
          let modalElement = angular.element('#g-confirm-dialog-' + $scope.id);
          let deferred = $q.defer();

          // Some small delay is needed because when multiple modal are
          // displayed after each other the modal after the first one will
          // not open
          $timeout(function() {
            modalElement.modal('show');
          }, 300);
          $timeout(function() {
            modalElement.modal('hide');

            deferred.resolve();
          }, 2000);

          return deferred.promise;
        }
      });
    }]
  };
});
