/**
 * This directive renders the Vote Collectors checkbox list and
 * keeps track if the selection is correct.
 *
 * Note that this directive modifies the model which is passed in by adding
 * additions flags to the model.
 *
 * The rule is:
 *
 * - At least one non Agent Vote Collector needs to be ticked
 * - For non Agent Vote Collector which has already votes assigned, at least one
 * Vote Collector should be ticked.
 *
 */
import { sortVcSelection } from '@/shared/services/menus/votecollector';

angular.module('generalMeetingMainModule').directive('vcGroup', function () {
  return {
    scope: {
      name: '@name',
      additionalSC: '=shareclassData',
      isNoneSelected: '=?isNoneSelected',
      isGroupsValid: '=?isGroupValid',
      required: '=required',
      onOpenSetGroupNameDialog: '&onOpenSetGroupNameDialog',
    },
    templateUrl: 'features/modules/create-gm-module/vcGroup-template.html',
    controller: [
      '$scope',
      function ($scope) {
        $scope.isGroupsValid === undefined ? true : $scope.isGroupsValid;
        $scope.isNoneSelected === undefined ? false : $scope.isNoneSelected;

        $scope.$watch('additionalSC', function (value) {
          if (value) {
            validateAndUpdateScope(value);
          }
        });
        $scope.changeVc = function (shareClass) {
          validateAndUpdateScope(shareClass);
        };

        $scope.openSetGroupNameDialog = function (
          groupId,
          groupName,
          isAgent,
          isIssuer
        ) {
          $scope.onOpenSetGroupNameDialog({
            groupId: groupId,
            groupName: groupName,
            isAgent: isAgent,
            isIssuer: isIssuer,
          });
        };

        let validateAndUpdateScope = function (shareClass) {
          // sort the vc list: Agent, Issuer, Proxy VC
          shareClass.authorizedVoteCollectorList = sortVcSelection(
            shareClass.authorizedVoteCollectorList
          );

          let authorizedVoteCollectorList =
            shareClass.authorizedVoteCollectorList;

          $scope.isGroupsValid = validateAndUpdateEachVcGroup(
            authorizedVoteCollectorList
          );
          $scope.isNoneSelected = !validateAtLeastOneVcSelected(
            authorizedVoteCollectorList
          );

          //It's a hack to put value on the hidden input box with required validation
          if ($scope.isGroupsValid) {
            $scope.vcValid = 'valid';
          } else {
            $scope.vcValid = '';
          }
        };

        /**
         * Validate to have at least one non-agent vote collector is selected.
         *
         * @param authorizedVoteCollectorList
         */
        let validateAtLeastOneVcSelected = function (
          authorizedVoteCollectorList
        ) {
          let allNonAgentVC = authorizedVoteCollectorList.filter(function (vc) {
            return !vc.isAgent;
          });
          let allNonDeletedNonAgentVC = allNonAgentVC.filter(function (vc) {
            return !vc.isDeleted;
          });

          if (allNonAgentVC.length > 0 && allNonDeletedNonAgentVC.length > 0) {
            return true;
          } else {
            return false;
          }
        };

        /**
         * Validate if each vc which has votes has at least one
         * vote collector selected.
         *
         * @param authorizedVoteCollectorList
         */
        let validateAndUpdateEachVcGroup = function (
          authorizedVoteCollectorList
        ) {
          let hasAnyIncompleteGroup = false;
          // a list of vc group ids
          let distinctVcGroupIds = authorizedVoteCollectorList
            .map(function (vc) {
              return vc.pseudoVcGroup;
            })
            .filter(function (item, index, array) {
              return array.indexOf(item) == index;
            });

          distinctVcGroupIds.forEach(function (groupId) {
            //All vc which has active votes
            let vcsInGroup = authorizedVoteCollectorList.filter(function (vc) {
              return vc.pseudoVcGroup == groupId && vc.hasActiveVotes;
            });

            if (vcsInGroup.length > 0) {
              let deletedVcsInGroup = vcsInGroup.filter(function (vc) {
                return vc.isDeleted;
              });
              // if all are deleted
              if (vcsInGroup.length == deletedVcsInGroup.length) {
                vcsInGroup[0].isAllVcDeleted = true;
                hasAnyIncompleteGroup = hasAnyIncompleteGroup || true;
              } else {
                vcsInGroup[0].isAllVcDeleted = false;
                hasAnyIncompleteGroup = hasAnyIncompleteGroup || false;
              }
            }
          });

          return !hasAnyIncompleteGroup;
        };
      },
    ],
  };
});
