angular.module('UIControlModule').directive('externalContent',
    ['$window', function ($window) {
      return {
        scope: {
          id: '@id',
          src: '@src'
        },
        template: '<iframe id="{{id}}" data-ng-src="{{src}}" style="width:100%; border:0px" scrolling="no"></iframe>',
        controller: ['$scope', '$element', '$timeout', function ($scope, $element, $timeout) {
          const defaultHeight = 100;
          const iframeElement = $element.find('iframe')[0];

          // contentWindow needs to be store on initialization, because
          // somehow it becomes null when accessed through
          // iFrameElement.contentWindow on resize event
          const contentWindow = iframeElement.contentWindow;

          // On resize of window recalculate and set height
          angular.element($window).on('resize', function () {
            resize(iframeElement);
          });

          // On finish loading of iframe recalculate and set height
          $element.find('iframe')[0].onload = function (event) {
            addLinkTargetBlank();

            let iframe = event.target;
            resize(iframe);

            // Sometimes the height doesn't get calculated correctly, then
            // retry resize with a timeout
            let iframeHeight = getContentHeight();
            if (iframeHeight === 0 || iframeHeight === defaultHeight) {
              $timeout(function() {
                resize(iframe);
              }, 500);

            }
          }

          function resize(iframe) {
            // Size needs to be reset-ed first otherwise the content
            // height will keep growing and growing
            iframe.style.height = defaultHeight + "px";
            iframe.style.height = getContentHeight() + 'px';
          }

          function getContentHeight() {
            return contentWindow.document.body.offsetHeight
          }

          /**
           * Ensure that all links are opened in a new window/tab
           */
          function addLinkTargetBlank() {
            let baseElement = document.createElement("base");
            baseElement.target = "_blank";
            contentWindow.document.head.appendChild(baseElement);
          }
        }]
      };
    }]);
