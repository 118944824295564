/**
 * @ngdoc directive
 * @module richTextEditorModule
 * @name richTextEditor,
 * @description richTextEditor - used to add rich text editor template to the
 *              pages. contentArea - used to add content area. Also the content
 *              will be validated.
 */
angular.module('richTextEditorModule', ['commonModule', 'ngStorage'])
        .directive(
                'richTextEditor',
                [function() {
                  var directive = {};
                  directive.restrict = 'E';
                  directive.replace = true;
                  directive.scope = {
                    ngModel: '=',
                    id: '@',
                    height: '@',
                    required: '=?',
                    name: '@',
                    maximumlength: '@'
                  };
                  var controller = function($scope, $window, $timeout) {
                    if (!$scope.id) {
                      $scope.id = 'richTextArea';
                    }
                    if (!$scope.height) {
                      $scope.height = '200px';
                    }
                    if (!$scope.maximumlength) {
                      $scope.maximumlength = 10000;
                    }
                    if (!$scope.required) {
                      $scope.required = false;
                    }
                    if (!$scope.name) {
                      $scope.name = $scope.id;
                    }
                    $scope.formatText = function(format, value) {
                      var param = null;
                      if (value) {
                        param = value;
                      }
                      if (format === 'backColor') {
                        $scope.color = !$scope.color;
                        param = 'white';
                        if ($scope.color) {
                          param = 'yellow';
                        }
                      }
                      $window.document.execCommand(format, false, param);
                      $scope.setFocus();
                    };
                    $scope.setFocus = function() {
                      $timeout(
                              function() {
                                if (($window.navigator.userAgent
                                        .indexOf('Chrome') > 0 || $window.navigator.userAgent
                                        .indexOf('Safari') > 0)) {
                                  $('#' + $scope.id).attr('tabindex', -1)
                                          .focus();
                                } else {
                                  $('#' + $scope.id).focus();
                                }
                              }, 1);
                    };
                  };
                  directive.controller = ['$scope', '$window', '$timeout',
                      '$parse', controller];
                  directive.templateUrl = 'shared/directives/templates/richtext-editor-template.html';
                  return directive;
                }]);
angular.module('richTextEditorModule')
        .directive(
                'richTextEditorWithoutButtons',
                [function() {
                  var directive = {};
                  directive.restrict = 'E';
                  directive.replace = true;
                  directive.scope = {
                    ngModel: '=',
                    id: '@',
                    height: '@',
                    required: '=?',
                    name: '@',
                    maximumlength: '@'
                  };
                  var controller = function($scope, $window, $timeout) {
                    if (!$scope.id) {
                      $scope.id = 'richTextArea';
                    }
                    if (!$scope.height) {
                      $scope.height = '200px';
                    }
                    if (!$scope.maximumlength) {
                      $scope.maximumlength = 10000;
                    }
                    if (!$scope.required) {
                      $scope.required = false;
                    }
                    if (!$scope.name) {
                      $scope.name = $scope.id;
                    }
                    $scope.formatText = function(format, value) {
                      var param = value ? value : null;
                      if (format === 'backColor') {
                        $scope.color = !$scope.color;
                        param = $scope.color ? 'yellow' : 'white';
                      }
                      $window.document.execCommand(format, false, param);
                      $scope.setFocus();
                    };
                    $scope.setFocus = function() {
                      $timeout(
                              function() {
                                if (($window.navigator.userAgent
                                        .indexOf('Chrome') > 0 || $window.navigator.userAgent
                                        .indexOf('Safari') > 0)) {
                                  $('#' + $scope.id).attr('tabindex', -1)
                                          .focus();
                                } else {
                                  $('#' + $scope.id).focus();
                                }
                              }, 1);
                    };
                  };
                  directive.controller = ['$scope', '$window', '$timeout',
                      '$parse', controller];
                  directive.templateUrl = 'shared/directives/templates/richtext-editor-withoutButtons-template.html';
                  return directive;
                }]);
angular.module('richTextEditorModule')
        .directive(
                'contentArea',
                ['$filter', function($filter) {
                  var directive = {};
                  directive.restrict = 'E';
                  directive.require = ['^ngModel', '^form'];
                  directive.replace = true;
                  directive.scope = {
                    id: '@',
                    height: '@',
                    requiredfield: '=?',
                    name: '@',
                    maximumlength: '@'
                  };
                  directive.controller = [
                      '$scope',
                      '$window',
                    function ($scope, $window) {
                      $scope.handlePaste = function (event) {
                        event.preventDefault();
                        event.stopPropagation();

                        if (event.originalEvent && event.originalEvent.clipboardData) {
                          let plaintext = event.originalEvent.clipboardData.getData('text/plain');
                          document.execCommand('inserttext', false, plaintext);
                        } else {
                          // Browser fallback
                          // IE11 thinks the event is a drag event it doesn't have
                          // the clipboardData in the event
                          // and it doesn't support execCommand('inserttext')
                          let plaintext = $window.clipboardData.getData('Text');
                          document.execCommand('paste', false, plaintext);
                        }
                      };
                    }];
                  directive.template = '<div id="{{id}}" name="{{name}}" ng-style="style" contenteditable="true" class="content-area" tabindex="1" ng-paste="handlePaste($event)"></div>';
                  directive.compile = function() {
                    return function(scope, element, attrs, controllers) {
                      var ngModelCtrl = controllers[0];
                      scope.style = {
                        'height': scope.height
                      };
                      element
                              .bind(
                                      'blur keyup change',
                                      function(event) {
                                        scope.$apply(function() {
                                                  ngModelCtrl.$setValidity(
                                                          'required', true);
                                                  if (element[0].innerText.length > 0) {
                                                    if (element[0].innerText.trim() === ''
                                                            && scope.requiredfield) {
                                                      ngModelCtrl .$setValidity('required',false);
                                                    } else {
                                                      ngModelCtrl.$setValidity('required', true);
                                                    }
                                                    if (element[0].innerText.length > parseInt(
                                                        scope.maximumlength, 10)) {
                                                      ngModelCtrl.$setValidity('maxlength',false);
                                                    } else {
                                                      ngModelCtrl.$setValidity('maxlength',true);
                                                    }
                                                    ngModelCtrl.$setViewValue(element.html());
                                                  } else {
                                                    ngModelCtrl.$setViewValue('');
                                                  }
                                                });
                                      });
                      scope.$watch('requiredfield', function(value, oldvalue) {
                        if (element[0].innerText.trim() === '' && scope.requiredfield) {
                          ngModelCtrl.$setValidity('required', false);
                        } else {
                          ngModelCtrl.$setValidity('required', true);
                        }
                      });
                      ngModelCtrl.$render = function() {
                        element.html($filter('htmlDecode')(ngModelCtrl.$viewValue).toString());
                        ngModelCtrl.$setViewValue(element.html());
                      };
                    };
                  };
                  return directive;
                }]);
